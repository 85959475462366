
<template>
  <div class="articlesadd-wrap">
    <div class="add-title">
      <form id="backform" method="post" action="article-add.php">
        <input type="hidden" name="article_id" value="<?php echo $_POST['article_id']?>"/>
      </form>
      <a @click="back" class="add-back">To Article</a>
      Hi, post your awesome findings to others!
    </div>
    <div class="add-step">
      <ul>
        <li class="cur angle">1. Add a New Article</li>
        <li class="cur">2. Add Specific Questions</li>
        <li>3. Preview</li>
      </ul>
    </div>
    <div class="add-box" id="questions">
      <div v-for="(a, index) in Ans" :key="index" class="w100">
        <h2>Specific Question {{ index + 1 }}</h2>
        <div class="add-item">
          <label class="add-label">Question:</label>
          <input type="text" name="question" placeholder="" id="title" class="add-input" v-model="a[0]">
        </div>
        <div class="add-item">
          <label class="add-label">Answer choices:</label>
          <div class="itme-flex">
            <div class="w50">
              <span>A:</span>
              <input type="text" name="a1" placeholder="" id="title" class="add-input" v-model="a[1]">
            </div>
            <div class="w50">
              <span>B:</span>
              <input type="text" name="a2" placeholder="" id="title" class="add-input" v-model="a[2]">
            </div>
            <div class="w50">
              <span>C:</span>
              <input type="text" name="a3" placeholder="" id="title" class="add-input" v-model="a[3]">
            </div>
            <div class="w50">
              <span>D:</span>
              <input type="text" name="a4" placeholder="" id="title" class="add-input" v-model="a[4]">
            </div>
          </div>
        </div>
        <div class="add-item">
          <label class="add-label">Correct choice</label>
          <div class="correct-choice">
            <button 
              v-for="option in [1, 2, 3, 4]" 
              :key="option" 
              @click="setCorrectChoice(index, option)"
              :class="{'active': a[5] === option}">
              {{ ['A', 'B', 'C', 'D'][option - 1] }}
            </button>
          </div>
          <div class="delete-question" @click="deleteQuestion(index)">
            Delete this question
          </div>
        </div>
      </div>
      <div class="btn-newque add-line" @click="addNewQuestion">
        <a><font-awesome-icon icon="circle-plus" style="font-size: 30px; margin-right: 14px; color: #090970;"/>Add a new question</a>
      </div>
    </div>
    <div class="add-btn">
      <div v-if="reviewStatus === 'modifyquestionlv3'" class="form-btn" @click="submitQuestions">Submit</div>
      <div v-else>
        <div class="form-btn" @click="next">Next</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { post } from "@/api"; // Import post method for API interaction

export default {
  name: "questionsAdd",
  setup() {
    const Ans = ref([new Array(7).fill("")]);
    const articleId = ref(-1);
    const reviewStatus = ref(null);
    const router = useRouter();
    const userId=ref(-1);

    onMounted(() => {
      const storedArticleId = sessionStorage.getItem("articleId");
      const userId=sessionStorage.getItem("userId")
      articleId.value = parseInt(storedArticleId, 10);
      if (!articleId.value) {
        ElMessage.error("Article ID not found.");
        return;
      }
      reviewStatus.value = sessionStorage.getItem("reviewStatus");
      console.log(reviewStatus)
      checkIfQuestionsUploaded();
    });

    const checkIfQuestionsUploaded = () => {
      post('/admin/is-question-uploaded', { articleId: articleId.value,moderator: userId.value}).then(questionRes => {
        if (questionRes.res === 0) {
          // Questions not uploaded yet
          loadExistingQuestions();
        } else if (questionRes.res === 1) {
          // Questions uploaded but not all approved
          loadExistingQuestions();
        }
      });
    };

    const loadExistingQuestions = () => {
      post("/question/get-question", { articleId: articleId.value }).then((res) => {
        if (res.questions && res.questions.length > 0) {
          Ans.value = res.questions.map((question) => [
            question.question,
            question.choice1,
            question.choice2,
            question.choice3,
            question.choice4,
            question.correctChoice,
            question.id,
          ]);
        }
      }).catch(() => {
        ElMessage.error("Failed to fetch questions from the server.");
      });
    };

    const addNewQuestion = () => {
      Ans.value.push(new Array(7).fill(""));
    };

    const deleteQuestion = (index) => {
      Ans.value.splice(index, 1);
    };

    const next = () => {
      if (Ans.value.length === 0) {
        ElMessage({
          message: "Please add at least one question.",
          type: "warning",
        });
        return;
      }

      let flag = true;
      for (let i in Ans.value) {
        if (Ans.value[i][6] === "") {
          Ans.value[i][6] = -1;
        }
        Ans.value[i].filter(a => {
          if (a === "undefined" || a === "") {
            flag = false;
          }
        });
      }

      if (flag) {
        for (let i in Ans.value) {
          Ans.value.forEach((question, index) => {
            sessionStorage.setItem("newQuestion" + index, JSON.stringify(question));
          });
        }
        router.push("/preview-add");
      } else {
        ElMessage({
          message: "Please fill the empty item(s).",
          type: "warning",
        });
      }
    };

    const submitQuestions = () => {
      if (Ans.value.length === 0) {
        ElMessage({
          message: "Please add at least one question.",
          type: "warning",
        });
        return;
      }

      ElMessageBox.confirm('Are you sure you want to submit the questions?', 'Confirmation', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        submitQuestionsToAPI();
      }).catch(() => {
        ElMessage({
          type: 'info',
          message: 'Submission cancelled',
        });
      });
    };

    const submitQuestionsToAPI = () => {
      if (Ans.value.length === 0) {
        ElMessage({
          message: "Please add at least one question.",
          type: "warning",
        });
        return;
      }

      const questions = Ans.value.map(question => ({
        id: question[6] === -1 ? null : question[6],
        question: question[0],
        options: question.slice(1, 5),
        correctChoice: question[5],
        points: 2,
        articleId: articleId.value
      }));

      console.log(questions);

      ElMessageBox.confirm('Are you sure you want to submit the questions?', 'Confirmation', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        post('/admin/modify-question', { questions:questions,moderator:userId.value })
          .then(() => {
            ElMessage({
              message: 'Questions successfully modified!',
              type: 'success',
            });
            router.push('/');
          })
          .catch(() => {
            ElMessage.error('Failed to modify questions. Please try again.');
          });
      }).catch(() => {
        ElMessage({
          type: 'info',
          message: 'Submission cancelled',
        });
      });
    };



    const setCorrectChoice = (index, option) => {
      Ans.value[index][5] = option;
    };

    const back = () => {
      router.push("/article-add");
    };

    watch(
      () => Ans.value,
      () => {
        sessionStorage.setItem("revise", 1);
      },
      {
        deep: true,
      }
    );

    return {
      Ans,
      next,
      submitQuestions,
      back,
      addNewQuestion,
      deleteQuestion,
      articleId,
      reviewStatus,
      setCorrectChoice
    };
  },
};
</script>

<style scoped>
.articlesadd-wrap{
    display: block;
    width: 1100px;
    margin: 0 auto;
}
.add-title{
    display: flex;
    height: 126px;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #14279B;
    font-family: "Poppins-Bold";
    position: relative;
}
.add-back{
    position: absolute;
    left: 0;
    top: 22px;
    width: 93px;
    height: 35px;
    padding: 0 15px 0 38px;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #14279B;
    border-radius: 18px;
    border: 2px solid #111880;
    font-family: "Poppins-SemiBold";
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2);
    background: no-repeat 12px center;
    background-size: 19px 19px;
}
.add-back:hover,.add-save:hover{
    color: #0e1792;
    border: 2px solid #0e1792;
    box-shadow: 0px 2px 20px 0px rgba(59,93,245,0.5);
}
.add-step{
    display: block;
    width: 1100px;
    height: 45px;
    border-radius: 26px;
    overflow: hidden;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2);
}
.add-step ul{
    display: flex;
}
.add-step li{
    width: 33.33%;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    color: #14279B;
    font-family: "Poppins-Bold";
    text-align: center;
    /* background:url(../images/icon-step.png) no-repeat 88% center; */
    background-size: 24px 25px;
}
.add-step li.cur{
    color: #FFFFFF;
    border-radius: 0 23px 23px 0;
    background:#5473FF no-repeat 88% center;
    background-size: 24px 25px;
}
.add-step li.cur.angle{
    border-radius: 0;
}
.add-box{
    display: block;
    width: 100%;
    margin-top: 30px;
    /*padding: 45px 80px 30px 80px; */
    height: auto;
    border-radius: 30px;
    margin-bottom: 15px;
    overflow: hidden;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2);
}
.add-box.preview{
    padding: 0 32px 20px 32px; 
}
.add-box.preview .articles-wrap{
    width: 100%;
}
.add-box.preview .articles-banner{
    width: 100%;
    background-color: #98CA9F;
}
.add-box h2{
    display: block;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 24px;
    font-family: "Poppins-Bold";
    color: #14279B;
}
.w100{
  width: 100%;
}
.add-item{
    display: block;
    margin: 25px;
    position: relative;
}
.add-item.flex{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.add-item.flex .w50{
    width: 45%;
}
.add-label{
    display: block;
    font-size: 16px;
    font-family: "Poppins-Bold";
    margin-bottom: 15px;
}
.add-label-block{
    display: block;
}
.add-input{
    width: 100%;
    height: 45px;
    font-size: 15px;
    padding-top: 10px;
    background-color: #EFF1F5;
    border: none;
    border-radius: 15px;
}
.add-textarea{
    width: 100%;
    font-size: 15px;
    padding: 20px 20px;
    background-color: #EFF1F5;
    border: none;
    border-radius: 15px;
    min-height: 100px;
}
.add-input.w135{
    width: 135px;
}
.add-input:hover,.add-input:focus{
    background-color: #e5e9f2;
}

.itme-flex{
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}
.itme-flex .w50{
    display: flex;
    width: 45%;
    align-items: center;
    margin: 7.5px 0;
}
.itme-flex .w50:hover{
  cursor: pointer;
  color: #14279B;
}
.itme-flex span{
    font-size: 16px;
    margin-right: 12px;
}

.btn-newque{
    display: block;
    padding: 50px 0 20px 0;
}
.btn-newque a{
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    color: #000000;
}
.btn-newque a img{
    width: 29px;
    height: 29px;
    margin-right: 10px;
}
.btn-newque a:hover{
    color: #14279B;
  cursor: pointer;
}
.add-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 175px;
    padding-bottom: 30px;
    position: relative;
}
.add-btn .submit-tips{
    bottom: 150px;
    height: 130px;
}
.add-save{
    position: absolute;
    left: 0;
    top: 22px;
    width: 93px;
    height: 35px;
    padding: 0 15px 0 38px;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #14279B;
    border-radius: 18px;
    border: 2px solid #111880;
    font-family: "Poppins-SemiBold";
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2);
    background: no-repeat 12px center;
    background-size: 19px 19px;
}
.add-back:hover,.add-save:hover{
    color: #0e1792;
    border: 2px solid #0e1792;
    box-shadow: 0px 2px 20px 0px rgba(59,93,245,0.5);
}
.form-btn{
    display: block;
    width: 180px;
    height: 50px;
    font-size: 20px;
    color: #FFF !important;
    line-height: 50px;
    text-align: center;
    border: none;
    margin: 0 auto;
    border-radius: 25px;
    cursor: pointer;
    font-family: "Poppins-Black";
    background-color: #5473FF;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2);
}
.form-btn:hover{
    background-color: #4261f0;
    box-shadow: 0px 2px 20px 0px rgba(59,93,245,0.5);
}

.correct-choice {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.correct-choice button {
  padding: 10px 50px;
  border: 2px solid #ccc;
  border-radius: 20px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  font-size: 16px;
}

.correct-choice button.active {
  background-color: #5473FF;
  color: white;
  border-color: #5473FF;
}

.correct-choice button:hover {
  border-color: #5473FF;
}
.delete-question:hover{
  cursor:pointer;
  color: #5473FF;
}

.delete-question{
  font-size: 16px;
  margin: 15px;
}
</style>
